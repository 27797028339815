import Vue from 'vue'
import VueI18n from 'vue-i18n'
import LocaleServcie from './locales/LocaleService.js'

Vue.use(VueI18n)

function loadLocaleMessages(){
  const messages = LocaleServcie.getAllTransLations();
  //console.log(messages);
  return messages;
}


export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: loadLocaleMessages()
})
